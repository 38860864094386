import React, { useContext } from 'react'
import Home from './pages/Home'
import Login from './pages/Login'
import LegalNotice from './pages/LegalNotice'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import AccessDenied from './pages/AccessDenied'
import NotFound from './pages/NotFound'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ProtectedRoute } from './components/router/ProtectedRoute'
import { UserContext } from './stores/UserStore'

const Router = () => {
  const { authState } = useContext(UserContext)

  const protectedRouteProps = {
    isAuthenticated: (authState === 'SIGNED_IN'),
    authenticationPath: '/login'
  }

  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path='/' component={Home} {...protectedRouteProps}/>
        <Route path='/login' component={Login} />
        <Route path='/legal-notice' component={LegalNotice} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/terms' component={Terms} />
        <ProtectedRoute path='/access-denied' component={AccessDenied} {...protectedRouteProps} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router