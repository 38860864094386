import React, { useContext, useState, useRef } from 'react'
import styled from 'styled-components'
import { Button, Link, FlexView } from '../../components/common'
import { Input, InputMessage, Checkbox } from '../../components/form'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../stores/UserStore'

const Label = styled.span`
  font-family: 'Roboto';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.darkGray}; 
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0px 5px 16px;
`

const Instruction = styled.span`
  font-family: 'Roboto';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: left;
  margin: 0px 5px 5px;
`

const Login = ({ setLoading, email, setEmail, signIn, forgotPassword, validation, setValidation, checkValidation, updateValue }) => {
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const form = useRef(null)
  const { t } = useTranslation()
  const { registryLog } = useContext(UserContext)

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const loggedIn = signIn && await signIn(email.toLowerCase(), password, rememberMe)
      !loggedIn && setLoading(false)
      registryLog('login', "click", "sign_in")
    }
    catch (e) {
      setLoading(false)
      setValidation({
        ...validation,
        userError: e.code || e
      })
      registryLog('login', "click", "sign_in_failed")
      console.log(e)
    }
  }

  return (
    <form style={{ width: '100%'}} ref={form} onSubmit={onSubmit}>
      <FlexView flexDirection="column" margin="10px 0px" width="100%">
        <Label>{t('login.label')}</Label>
        <Instruction>{t('login.instruction')}</Instruction>
        <Input
          name="email"
          style={{textTransform: 'lowercase'}}
          placeholder={t('email')}
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="email"
          width="100%"
          value={email}
          onChange={updateValue('email', setEmail)}
          onBlur={checkValidation}
          error={(validation.email.dirty && validation.email.error) || (validation.userError.length > 0)}
          required
        />
        {(validation.userError.length > 0) &&
          <InputMessage type="error">{t(`login.errors.${validation.userError}`)}</InputMessage>
        }
        <Input
          type="password"
          name="password"
          placeholder={t('password')}
          width="100%"
          onChange={updateValue('password', setPassword)}
          onBlur={checkValidation}
          error={validation.password.dirty && validation.password.error}
          required
        />
      </FlexView>
      <Checkbox checked={rememberMe} onChange={setRememberMe}>
        {t('Remember me')}
      </Checkbox>
      <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" width="100%" margin="10px 0px">
        <Link type="button" onClick={forgotPassword}>{t('login.forgotPassword')}</Link>
        <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
          {t('Login')}
        </Button>
      </FlexView>
    </form>
  )
}

export default Login
