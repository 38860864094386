import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import styled from 'styled-components'
import BackImage from '../assets/icons/back-arrow.svg'
import BackgroundImage from '../assets/images/background.jpg'
import LogoImage from '../assets/images/new-logo.png'
import { Card, FlexView, Anchor, LoadingOverlay } from '../components/common'
import LanguageSelect from '../containers/common/LanguageSelect'
import ChangePasswordForm from '../containers/user/ChangePasswordForm'
import ForgotPasswordForm from '../containers/user/ForgotPasswordForm'
import LoginForm from '../containers/user/LoginForm'
import { useWindowSize } from '../hooks/useWindowSize'
import { UserContext } from '../stores/UserStore'

const Background = styled.div`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background};
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
`
const ViewResizer = styled.div`
@media (min-height: 300px) {
  transform: scale(0.6)

  }
 @media (min-height: 500px) {
  transform: scale(0.8)

  }
  @media (min-height: 600px) {
   transform: scale(1)
  }
`

const Version = styled.div`
  font-family: Roboto;
  margin: 0px 0px -5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: bold;
`

const Logo = styled.img`
  width: 400px;
  margin: 20px auto 30px;
`

const LogoMedium = styled.img`
  width: 250px;
  margin: 5px auto 5px;
`

const Login = (props) => {
  const { t } = useTranslation()
  const { signIn, authState, changePassword, forgotPassword, sendRecoveryCode, recoverPassword, resetAuthState } = useContext(UserContext)
  const [email, setEmail] = useState('')
  const [isLoading, setLoading] = useState(false)
  const windowSize = useWindowSize()

  const [validation, setValidation] = useState({
    email: {
      dirty: false,
      error: false
    },
    password: {
      dirty: false,
      error: false
    },
    confirmPassword: {
      dirty: false,
      error: false
    },
    userError: ''
  })

  const redirectIfAuthenticated = () => {
    let redirect = null
    if (authState === 'SIGNED_IN') {
      const redirectPath = (props.location.state && props.location.state.from) ? props.location.state.from : '/'
      redirect = <Redirect to={`${redirectPath}${props.location.search}`} />
    }
    return redirect
  }

  const checkValidation = e => {
    setValidation({
      ...validation,
      [e.target.name]: {
        dirty: true,
        error: (e.target.validationMessage.length > 0)
      }
    })
  }

  const updateValue = (fieldName = null, setFunction) => (e) => {
    fieldName && validation[fieldName].dirty && checkValidation(e)
    setFunction(e.target.value)
  }

  const renderForm = () => {
    switch (authState) {
      case 'FORGOT_PASSWORD':
        return <ForgotPasswordForm {...{
          setLoading,
          email,
          setEmail,
          validation,
          setValidation,
          checkValidation,
          updateValue,
          sendRecoveryCode,
          resetAuthState,
          recoverPassword
        }} />
      case 'NEW_PASSWORD_REQUIRED':
        return <ChangePasswordForm {...{
          setLoading,
          validation,
          setValidation,
          checkValidation,
          updateValue,
          changePassword
        }} />
      default:
        return <LoginForm {...{
          setLoading,
          email,
          setEmail,
          signIn,
          forgotPassword,
          validation,
          setValidation,
          checkValidation,
          updateValue
        }} />
    }
  }

  return (
    <Background>
      {redirectIfAuthenticated()}
      <CSSTransition
        in={['FORGOT_PASSWORD', 'NEW_PASSWORD_REQUIRED'].includes(authState)}
        timeout={300}
        unmountOnExit
        classNames="fade"
      >
        <FlexView flexDirection="row" alignItems="flex-end" margin="10px 0px" style={{ cursor: 'pointer' }} onClick={resetAuthState}>
          <img src={BackImage} alt="Go back" style={{ marginRight: 10 }} />{t('Go Back')}
        </FlexView>
      </CSSTransition>
      <ViewResizer>
          <Card position="relative" width="80vw" maxWidth="460px" flexDirection="column" justifyContent="center" alignItems="center" elevation="medium" padding="24px 32px">
            <LoadingOverlay borderRadius="card" visible={isLoading} />
            {windowSize.height <= 700 ? <LogoMedium src={LogoImage} alt="Application Logo" /> : <Logo src={LogoImage} alt="Application Logo" />}
            <SwitchTransition>
              <CSSTransition
                key={authState}
                timeout={300}
                classNames="fade"
              >
                {renderForm()}
              </CSSTransition>
            </SwitchTransition>
            <Version>v{process.env.REACT_APP_VERSION}</Version>
          </Card>
          <FlexView flexDirection="row" maxWidth="524px" justifyContent="space-between" alignItems="center" margin="10px 0px">
            <LanguageSelect />
            <FlexView flexDirection="row" alignItems="center">
              <Anchor href="/legal-notice" color="gray" margin="0px 10px">{t('Legal Notice')}</Anchor>
              <Anchor href="/privacy" color="gray" margin="0px 10px">{t('Privacy')}</Anchor>
              <Anchor href="/terms" color="gray" margin="0px 10px">{t('Terms of Use')}</Anchor>
            </FlexView>
          </FlexView>
      </ViewResizer>
     
    </Background>
  )
}

export default Login