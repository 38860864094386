import styled, { css } from 'styled-components'

const Input = styled.input`
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '14px 15px'};
  margin: ${({ margin }) => margin || '6px 0px'};
  border: 2px solid #E0E0E0;
  outline: none;
  font-family: Roboto;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: bold;
  font-size: ${({ extraSmall, small, large, extraLarge, theme}) => {
    if (extraSmall) {
      return theme.fontSizes.extraSmall
    }
    if (small) {
      return theme.fontSizes.small
    }
    if (large) {
      return theme.fontSizes.large
    }
    if (extraLarge) {
      return theme.fontSizes.extraLarge
    }
    return theme.fontSizes.medium
  }};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.input};
  transition: border 0.2s linear;
  ${({ error, theme }) => error && css`border: 2px solid ${theme.colors.error};`}
  ${({ success, theme }) => success && css`border: 2px solid ${theme.colors.success};`}

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray };
  }
`

export default Input