import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.label`
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto;
  height: 24px;
  display: flex;
  flex-direction: row;
  user-select: none;
  align-items: center;
  width: fit-content;
  position: relative;
  margin: ${({ margin }) => margin || '0px'};
  cursor: pointer;
  & div {
    color: ${({ state, theme }) => state ? theme.colors.gray : theme.colors.primary };
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;
    transition: color .3s ease;

    &:last-child {
      color: ${({ state, theme }) => state ? theme.colors.primary : theme.colors.gray };
      margin-right: 0px;
    }
  }
  input {
    display: none;
    & + span {
      padding-left: 50px;
      min-height: 24px;
      line-height: 24px;
      display: block;
      color: ${({ theme }) => theme.colors.gray };
      position: relative;
      white-space: nowrap;
      transition: color .3s ease;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 12px;
      }
      &:before {
        top: 0;
        left: 0;
        width: 42px;
        height: 24px;
        background: ${({ theme }) => theme.colors.lightGray };
        transition: all .3s ease;
      }
      &:after {
        width: 18px;
        height: 18px;
        background: #fff;
        top: 3px;
        left: 3px;
        box-shadow: 0 1px 3px rgba(#121621, .1);
        transition: all .45s ease;
      }
      strong {
        font-weight: normal;
        position: relative;
        display: block;
        top: 1px;
        &:before,
        &:after {
          font-size: 14px;
          font-weight: 500;
          display: block;
          backface-visibility: hidden;
        }
        &:before {
          content: '${({ alternate, textOff }) =>  alternate ? '' : textOff}';
          transition: all .3s ease .2s;
        }
        &:after {
          content: '${({ alternate, textOn }) => alternate ? '' : textOn}';
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 0;
          color: ${({ alternate, theme }) => alternate ? theme.colors.gray : theme.colors.success };
          transition: all .3s ease;
          transform: translate(2px, 0);
        }
      }
    }

    &:checked {
      & + span {
        &:before {
          background: ${({ alternate, theme }) => alternate ? theme.colors.lighGray : theme.colors.success };
        }
        &:after {
          background: #fff;
          transform: translate(18px, 0);
        }
        strong {
          &:before {
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
            transform: translate(-2px, 0);
          }
          &:after {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
            transition: all .3s ease .2s;
          }
        }
      }
    }
  }
`

const Switch = ({ state, onChange, ...rest }) => {
  const handleHTMLEventChange = event => {
    onChange(event.target.checked)
  }

  return (
    <Wrapper state={state} {...rest} >
      {rest.alternate && <div>{rest.textOff}</div>}
      <input type="checkbox" checked={state} onChange={handleHTMLEventChange} />
      <span>
        <strong></strong>
      </span>
      {rest.alternate && <div>{rest.textOn}</div>}
    </Wrapper>
  )
}

Switch.propTypes = {
  state: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  alternate: PropTypes.bool,
  textOff: PropTypes.string.isRequired,
  textOn: PropTypes.string.isRequired
}

Switch.defaultProps = {
  onChange: null,
  alternate: false
}

export default Switch