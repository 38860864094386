import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, FlexView } from '../../components/common'
import { Input, InputMessage } from '../../components/form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Label = styled.span`
  font-family: 'Roboto';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0px 5px 10px;
`

const Instruction = styled.span`
  font-family: 'Roboto';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: left;
  margin: 0px 5px 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
`

const ChangePasswordForm = ({ setLoading, updateValue, checkValidation, validation, setValidation, changePassword }) => {
  const { t } = useTranslation()
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  useEffect(() => {
    setValidation({
      ...validation,
      userError: ''
    })

    return () => {
      setValidation({
        ...validation,
        userError: ''
      })
    }
  }, []) // eslint-disable-line

  const passwordMatchError = validation.confirmPassword.dirty && (confirmPassword !== newPassword)

  const onSubmit = changePassword && (async e => {
    e.preventDefault()
    if (confirmPassword === newPassword) {
      setLoading(true)
      try {
        await changePassword(newPassword)
        toast.success(t('login.messages.passwordChanged'))
        setLoading(false)
        setValidation({
          ...validation,
          userError: ''
        })
      }
      catch (e) {
        setLoading(false)
        setPasswordError(['InvalidPasswordException','InvalidParameterException'].includes(e.code) ? t(`login.errors.${e.code}`) : '')
        setValidation({
          ...validation,
          userError: ([
            'InvalidPasswordException',
            'InvalidParameterException'
          ].includes(e.code)) ? '' : (e.code || e),
        })
        console.log(e)
      }
    }
    else {
      setValidation({
        ...validation,
        email: {
          dirty: true,
          error: false
        },
        confirmPassword: {
          dirty: true,
          error: false
        }
      })
    }
  })

  return (
    <form style={{ width: '100%'}} onSubmit={onSubmit} autoComplete="off">
      <Wrapper>
        <Label>{t('login.newPasswordLabel')}</Label>
        <Instruction>{t('login.newPasswordInstruction')}</Instruction>
        <Input
          type="password"
          name="newPassword"
          placeholder={t('new password')}
          width="100%"
          autoComplete="new-password"
          onChange={updateValue(null, setNewPassword)}
          error={validation.userError || passwordError || passwordMatchError}
          required
        />
        {validation.userError && <InputMessage type="error">{t(`login.errors.${validation.userError}`)}</InputMessage>}
        {passwordError && <InputMessage type="error">{passwordError}</InputMessage>}
        {passwordMatchError && <InputMessage type="error">{t('login.errors.PasswordMatchError')}</InputMessage>}
        <Input
          type="password"
          name="confirmPassword"
          placeholder={t('confirm password')}
          width="100%"
          autoComplete="new-password"
          onChange={updateValue('confirmPassword', setConfirmPassword)}
          onBlur={checkValidation}
          error={passwordMatchError}
          required
        />
        <FlexView flexDirection="row" alignItems="center" justifyContent="flex-end" width="100%" margin="0px 0px 5px">
          <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
            {t('Change')}
          </Button>
        </FlexView>
      </Wrapper>
    </form>
  )
}

export default ChangePasswordForm