import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import { Card, FlexView, Button } from '../components/common'
import Logo from '../assets/images/new-logo.png'
import Blocked from '../assets/images/blocked.png'

const Background = styled.div`
  background-image: ${({ theme }) => theme.gradients.main};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

// const Copyrights = styled.div`
//   color: ${({ theme }) => theme.colors.gray};
// `

const ErrorCode = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: calc(${({ theme }) => theme.fontSizes.extraLarge} + 27px);
  background-image: ${({ theme }) => theme.gradients.main};
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`

const ErrorTitle = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: calc(${({ theme }) => theme.fontSizes.extraLarge} + 10px);
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 16px 0px 0px;
`

const ErrorMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-family: Roboto;
  text-align: center;
  width: 80%;
  margin: 32px 0px;
`

const ErrorWrapper = styled.div`
  background-image: url(${Blocked});
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: center;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AccessDenied = (props) => {
  const { t } = useTranslation()
  const [redirectHome, setRedirectHome] = useState(false)
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const goHome = () => {
    setRedirectHome(true)
  }

  return redirectHome ? <Redirect to='/' /> : (
    <Background>
      <Card width="90vw" height="80vh" elevation="veryHigh" padding="24px" justifyContent="space-between" alignItems="stretch">
        <img src={Logo} alt="Application Logo" width="160px" />
        <ErrorWrapper>
          <ErrorCode>404</ErrorCode>
          <ErrorTitle>{t('errors.404.title')}</ErrorTitle>
          <ErrorMessage>
            {t('errors.404.message.top')}
            <br/><br/>
            {t('errors.404.message.bottom')}
          </ErrorMessage>
          <FlexView flexDirection="row">
            <Button width="140px" backgroundColor="primary" color="white" margin="10px 5px" onClick={goBack}>{t('Go Back')}</Button>
            <Button width="140px" backgroundColor="secondary" color="white" margin="10px 5px" onClick={goHome}>{t('Home')}</Button>
          </FlexView>
        </ErrorWrapper>
        {/* <Copyrights>© 2019 - Vallourec</Copyrights> */}
      </Card>
    </Background>
  )
}

export default AccessDenied