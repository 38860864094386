import { useEffect } from 'react'
import queryString from 'query-string'

const Home = ({ location }) => {

  useEffect(() => {
    const redirectPath = queryString.parse(location.search).redirectTo || process.env.REACT_APP_HOME_URL

    window.location.assign(redirectPath)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default Home