import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.label`
  width: fit-content;
  margin: 16px 0px;
  display: block;
  text-align: left;
  user-select: none;
  cursor: pointer;

  input {
    display: none;

    &+span {
      height: 25px;
      padding-left: 25px;
      font-family: Roboto;
      font-size: ${({ fontSize, theme }) => theme.fontSizes[fontSize] || theme.fontSizes.medium};
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      &:not(:empty) {
        padding-left: 35px;
      }

      &:before,
      &:after {
        content: '';
        width: 25px;
        height: 25px;
        display: block;
        border-radius: 20%;
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
      }

      &:before {
        background: ${({ theme }) => theme.colors.lightGray};
        transition: background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 1.4);
      }

      &:after {
        background: #fff;
        transform: scale(.86);
        transition: transform .6s cubic-bezier(.175, .885, .32, 1.1);
      }
    }

    &:checked+span {
      &:before {
        transform: scale(1.04);
        background: ${({ theme }) => theme.colors.primary};
      }

      &:after {
        transform: scale(0);
        transition: transform .3s ease;
      }
    }
  }
`


const Checkbox = ({ checked, onChange, children, ...rest }) => {
  const handleHTMLEventChange = (event) => {
    onChange && onChange(event.target.checked)
  }

  return (
    <Wrapper {...rest}>
      <input type="checkbox" checked={checked} onChange={handleHTMLEventChange} />
      <span>{ children }</span>
    </Wrapper>
  )
}


Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.string
}

Checkbox.defaultProps = {
  onChange: null,
  children: ''
}

export default React.memo(Checkbox)