import styled from 'styled-components'


const Anchor = styled.a`
  font-family: Roboto;
  text-decoration: none;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
  color: ${({ color, theme}) => theme.colors[color] ? theme.colors[color] : theme.colors.primary};
  padding: ${({ padding }) => padding || '0px'};
  margin: ${({ margin }) => margin || '10px 0px'};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    opacity: 0.4;
  }
`

export default Anchor