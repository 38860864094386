import React from 'react'
import styled from 'styled-components'
import ErrorIcon from '../../assets/icons/cross.svg'
import WarningIcon from '../../assets/icons/warning.svg'
import SuccessIcon from '../../assets/icons/check.svg'
import InfoIcon from '../../assets/icons/info.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ centered }) => centered ? 'center' : 'flex-start'};
  align-items: center;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  margin: 5px 0px;
  font-family: Roboto;
  color: ${({ theme }) => theme.colors.metalic};
  vertical-align: middle;

  img {
    height: ${({ theme }) => theme.fontSizes.medium};
    margin-right: 8px;
    margin-bottom: 1px;
  }
`

const InputMessage = ({ type, children, ...rest }) => {
  let icon
  switch (type) {
    case 'error':
      icon = <img src={ErrorIcon} alt="Error"/>
      break
    case 'succes':
      icon = <img src={SuccessIcon} alt="Success"/>
      break
    case 'warning':
      icon = <img src={WarningIcon} alt="Warning"/>
      break
    case 'info':
      icon = <img src={InfoIcon} alt="Info"/>
      break
    default:
      icon = null
      break
  }

  return (
    <Wrapper {...rest}>
      {icon}
      {children}
    </Wrapper>
  )
}

export default React.memo(InputMessage)