import React, { useState, useEffect, useContext } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { Button, Link, FlexView } from '../../components/common'
import { Input, InputMessage } from '../../components/form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../stores/UserStore'

const Label = styled.span`
  font-family: 'Roboto';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0px 5px 10px;
`

const Instruction = styled.span`
  font-family: 'Roboto';
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: left;
  margin: 0px 5px 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
`

  const ForgotPasswordForm = ({ setLoading, email, setEmail, updateValue, checkValidation, validation, setValidation, sendRecoveryCode, recoverPassword }) => {
  const { registryLog, registryLogOld } = useContext(UserContext)
  const { t } = useTranslation()
  const [hasCode, setHasCode] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [codeError, setCodeError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [recoveryCode, setRecoveryCode] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const toggleView = () => {
    setHasCode(!hasCode)
    setValidation({
      ...validation,
      userError: ''
    })
  }

  useEffect(() => {
    setValidation({
      ...validation,
      userError: ''
    })

    return () => {
      setValidation({
        ...validation,
        userError: ''
      })
    }
  }, []) // eslint-disable-line

  const passwordMatchError = validation.confirmPassword.dirty && (confirmPassword !== newPassword)

  const onSubmit = recoverPassword && sendRecoveryCode && (async e => {
    e.preventDefault()
    if (confirmPassword === newPassword) {
      setLoading(true)
      try {
        if (hasCode) {
          await recoverPassword(email.toLowerCase(), recoveryCode, newPassword)
          toast.success(t('login.messages.passwordChanged'))
        }
        else {
          await sendRecoveryCode(email.toLowerCase())
          toast.success(t('login.messages.codeSent'))
          setHasCode(true)
          registryLogOld('change_password_request' , 'login')
          registryLog('login', "click", "change_password_request")
        }
        setLoading(false)
        setValidation({
          ...validation,
          userError: ''
        })
      }
      catch (e) {
        setLoading(false)
        setPasswordError(['InvalidPasswordException','InvalidParameterException'].includes(e.code) ? t(`login.errors.${e.code}`) : '')
        setCodeError(['CodeMismatchException', 'ExpiredCodeException'].includes(e.code) ? t(`login.errors.${e.code}`) : '')
        setValidation({
          ...validation,
          userError: ([
            'InvalidPasswordException',
            'InvalidParameterException',
            'CodeMismatchException',
            'ExpiredCodeException'
          ].includes(e.code)) ? '' : (e.code || e),
        })
        console.log(e)
      }
    }
    else {
      setValidation({
        ...validation,
        email: {
          dirty: true,
          error: false
        },
        confirmPassword: {
          dirty: true,
          error: false
        }
      })
    }
  })

  return (
    <form style={{ width: '100%'}} onSubmit={onSubmit} autoComplete="off">
      <input type="hidden" value="something" />
      <SwitchTransition>
        <CSSTransition
          key={hasCode ? 'recoverPassword' : 'sendRecoveryCode'}
          timeout={300}
          classNames="fade"
        >
        {hasCode ?
          <Wrapper>
            <Label>{t('login.newPasswordLabel')}</Label>
            <Instruction>{t('login.newPasswordInstruction')}</Instruction>
            <Input
              name="email"
              style={{textTransform: 'lowercase'}}
              placeholder={t('email')}
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              type="email"
              width="100%"
              value={email}
              onChange={updateValue('email', setEmail)}
              onBlur={checkValidation}
              error={(validation.email.dirty && validation.email.error) || (validation.userError.length > 0)}
              required
            />
            {(validation.userError.length > 0) &&
              <InputMessage type="error">{t(`login.errors.${validation.userError}`)}</InputMessage>
            }
            <Input
              name="recoveryCode"
              placeholder={t('recovery code')}
              width="100%"
              autocorrect="off"
              autocapitalize="off"
              autoComplete="new-password"
              value={recoveryCode}
              onChange={updateValue(null, setRecoveryCode)}
              error={codeError}
              required
            />
            {codeError && <InputMessage type="error">{codeError}</InputMessage>}
            <Input
              type="password"
              name="newPassword"
              autoComplete="new-password"
              placeholder={t('new password')}
              width="100%"
              onChange={updateValue(null, setNewPassword)}
              error={passwordError || passwordMatchError}
              required
            />
            {passwordError && <InputMessage type="error">{passwordError}</InputMessage>}
            {passwordMatchError && <InputMessage type="error">{t('login.errors.PasswordMatchError')}</InputMessage>}
            <Input
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              placeholder={t('confirm password')}
              width="100%"
              onChange={updateValue('confirmPassword', setConfirmPassword)}
              onBlur={checkValidation}
              error={passwordMatchError}
              required
            />
            <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" width="100%" margin="5px 0px 3px">
              <Link type="button" onClick={toggleView}>{t('login.sendCodeAgain')}</Link>
              <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
                {t('Change')}
              </Button>
            </FlexView>
          </Wrapper>
          :
          <Wrapper>
            <Label>{t('login.forgotPasswordLabel')}</Label>
            <Instruction>{t('login.forgotPasswordInstruction')}</Instruction>
              <Input
                name="email"
                placeholder={t('email')}
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                type="email"
                width="100%"
                value={email}
                onChange={updateValue('email', setEmail)}
                onBlur={checkValidation}
                error={(validation.email.dirty && validation.email.error) || (validation.userError.length > 0)}
                required
              />
              {(validation.userError.length > 0) &&
                <InputMessage type="error">{t(`login.errors.${validation.userError}`)}</InputMessage>
              }
              <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" width="100%" margin="0px 0px 3px">
                <Link type="button" onClick={toggleView}>{t('login.alreadyHaveCode')}</Link>
                <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
                  {t('Send')}
                </Button>
              </FlexView>
          </Wrapper>}
        </CSSTransition>
      </SwitchTransition>
    </form>
  )
}

export default ForgotPasswordForm
